@media screen and (min-width: 768px) {
  header {
    width: 100%;
    max-width: none;
  }
  header .header_inner .header_user {
    max-width: 1260px;
    margin: 10px auto 20px;
  }
  header .header_inner .header_main {
    max-width: 1260px;
    margin: auto;
  }
  header .header_inner nav > ul {
    max-width: 1260px;
    margin: 30px auto 0;
  }
}

@media screen and (max-width: 767px) {
  header .header_inner nav {
    display: block;
  }
}

main {
  width: 100%;
  max-width: none;
}

h2 {
  text-align: center;
}

.notification {
  text-align: center;
  font-size: 14px;
  line-height: 1.8;
  border-bottom: 2px solid #76c265;
  padding-bottom: 20px;
  margin: 0;
  color: #000;
}

@media screen and (max-width: 767px) {
  .notification {
    margin: 10px auto 0;
    border-bottom-width: 1px;
    font-size: 12px;
  }
}

.notification .notification_headline {
  color: #a8192e;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .notification .notification_headline {
    font-size: 14px;
  }
}

.mv_slider.slick-slider {
  margin-top: 20px;
  margin-bottom: 80px;
}

@media screen and (max-width: 767px) {
  .mv_slider.slick-slider {
    margin-bottom: 40px;
  }
}

.mv_slider.slick-slider .slick-arrow {
  display: none !important;
}

.mv_slider.slick-slider .slick-list .slick-slide img {
  width: 100%;
}

.mv_slider.slick-slider .slick-dots {
  bottom: -30px;
}

@media screen and (max-width: 767px) {
  .mv_slider.slick-slider .slick-dots {
    bottom: -25px;
  }
}

@media screen and (max-width: 767px) {
  .mv_slider.slick-slider .slick-dots li {
    margin: 0 1%;
  }
}

.mv_slider.slick-slider .slick-dots li button::before {
  color: #d0d0d0;
  font-size: 20px;
  opacity: 1;
}

@media screen and (max-width: 767px) {
  .mv_slider.slick-slider .slick-dots li button::before {
    font-size: 16px;
  }
}

.mv_slider.slick-slider .slick-dots li.slick-active button::before {
  color: #3b5343;
}

.banner div:not(:first-child) {
  margin-top: 40px;
}

@media screen and (max-width: 767px) {
  .banner div:not(:first-child) {
    margin-top: 10px;
  }
}

.banner div img {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .feature {
    justify-content: center;
    margin-bottom: -20px;
  }
  .feature .feature_box {
    max-width: 400px;
    width: 30%;
    margin: 0 15px 30px;
  }
  .feature .feature_box.feature_box:not(:nth-child(3n)) {
    margin: 0 15px 30px;
  }
  .feature .feature_box.feature_box:nth-child(n + 4) {
    margin-top: 0;
  }
}

@media screen and (min-width: 768px) {
  .special {
    justify-content: center;
    margin-bottom: -20px;
  }
  .special div {
    max-width: 400px;
    width: 30%;
    margin: 0 15px 20px;
  }
  .special div:not(:first-child) {
    margin-left: 15px;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) {
  .special div:not(:nth-child(3n)) {
    margin-right: 15px;
  }
}

@media screen and (min-width: 768px) {
  .suggest_area.recent,
  .suggest_area:not(.recent) {
    max-width: 1260px;
    margin: 40px auto 0;
  }
  .suggest_area.recent .suggest_area_inner,
  .suggest_area:not(.recent) .suggest_area_inner {
    flex-wrap: nowrap;
  }
  .suggest_area.recent .suggest_area_inner .suggest_item,
  .suggest_area:not(.recent) .suggest_area_inner .suggest_item {
    width: 20%;
  }
  .suggest_area.recent .suggest_area_inner .suggest_item a .item_thumbnail_img,
  .suggest_area:not(.recent) .suggest_area_inner .suggest_item a .item_thumbnail_img {
    height: auto;
  }
}

@media screen and (min-width: 768px) {
  .category_area {
    justify-content: center;
  }
  .category_area dl {
    max-width: 400px;
    width: 30%;
    margin: 0 15px 20px;
  }
  .category_area dl:not(:nth-of-type(3n + 1)) {
    margin: 0 15px 20px;
  }
  .category_area dl:nth-of-type(n + 4) {
    margin-top: 0;
  }
}

@media screen and (min-width: 768px) {
  footer {
    width: 100%;
    max-width: none;
  }
  footer .footer_block .footer_inner {
    max-width: 1260px;
    margin: auto;
  }
}
