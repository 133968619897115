@import "_vars";

header {
  @include min-screen($pc_size) {
    width: 100%;
    max-width: none;
    .header_inner {
      .header_user {
        max-width: $contents_width;
        margin: 10px auto 20px;
      }
      .header_main {
        max-width: $contents_width;
        margin: auto;
      }
      nav {
        > ul {
          max-width: $contents_width;
          margin: 30px auto 0;  
        }
      }
    }
  }
  @include max-screen($sp_size) {
    .header_inner{
      nav {
        display: block;
      }      
    }
  }
}

main {
  width: 100%;
  max-width: none;
}

h2 {
  text-align: center;
}

.notification {
  text-align: center;
  font-size: 14px;
  line-height: 1.8;
  border-bottom: 2px solid $color_border_lime;
  padding-bottom: 20px;
  margin: 0;
  color: $color_text_black;
  @include max-screen($sp_size) {
    margin: 10px auto 0;
    border-bottom-width: 1px;
    font-size: 12px;
  }

  .notification_headline {
    color: $color_attention;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    @include max-screen($sp_size) {
      font-size: 14px;
    }
  }
}

.mv_slider.slick-slider {
  margin-top: 20px;
  margin-bottom: 80px;
  @include max-screen($sp_size) {
    margin-bottom: 40px;
  }

  .slick-arrow {
    display: none !important;
  }

  .slick-list {
    .slick-slide {
      img {
        width: 100%;
      }
    }
  }

  .slick-dots {
    bottom: -30px;
    @include max-screen($sp_size) {
      bottom: -25px;
    }
    li {
      @include max-screen($sp_size) {
        margin: 0 1%;
      }

      button {
        &::before {
          color: $color_border_gray;
          font-size: 20px;
          opacity: 1;
          @include max-screen($sp_size) {
            font-size: 16px;
          }
        }
      }

      &.slick-active {
        button {
          &::before {
            color: $color_main_dark;
          }
        }
      }
    }
  }
}

.banner {
  div {
    &:not(:first-child) {
      margin-top: 40px;
      @include max-screen($sp_size) {
        margin-top: 10px;
      }
    }

    img {
      width: 100%;
    }
  }
}

.feature {
  @include min-screen($pc_size) {
    justify-content: center;
    margin-bottom: -20px;    
    .feature_box {
      max-width: 400px;
      width: 30%;
      margin: 0 15px 30px;
      &.feature_box:not(:nth-child(3n)) {
        margin: 0 15px 30px;
      }
      &.feature_box:nth-child(n + 4) {
        margin-top: 0;
      }
    }
  }
}

.special {
  @include min-screen($pc_size) {
    justify-content: center;
    margin-bottom: -20px;
    div {
      max-width: 400px;
      width: 30%;
      margin: 0 15px 20px;
      &:not(:first-child) {
        margin-left: 15px;      
      }
      &:not(:nth-child(3n)) {
        @include min-screen($pc_size) {
          margin-right: 15px;
        }
      }
    }
  }
}

.suggest_area.recent,
.suggest_area:not(.recent) {
  @include min-screen($pc_size) {
    max-width: $contents_width;
    margin: 40px auto 0;
    .suggest_area_inner {
      flex-wrap: nowrap;
      .suggest_item {
        width: 20%;
        a .item_thumbnail_img {
          height: auto;
        }
      }
    }    
  }
}

.category_area {
  @include min-screen($pc_size) {
    justify-content: center;
    dl {
      max-width: 400px;
      width: 30%;
      margin: 0 15px 20px;
      &:not(:nth-of-type(3n + 1)) {
        margin: 0 15px 20px;
      }
      &:nth-of-type(n + 4) {
        margin-top: 0;
      }
    }    
  }
}

footer {
  @include min-screen($pc_size) {
    width: 100%;
    max-width: none;
    .footer_block {
      .footer_inner {
        max-width: $contents_width;
        margin: auto;
      }
    }    
  }
}